import { Author } from 'interfaces/content/Authors';
import { MediaItem } from 'interfaces/content/Media';
import { PostSettings } from 'interfaces/content/articles/PostSettings';
import { Faq } from 'interfaces/components/Faq';
import { BypassPaywallResponse } from 'interfaces/BypassPaywall';

export interface PremiumCategory {
  name: string;
  premiumCategoryId: number;
}

export interface PrimaryPostTag {
  name: string;
  primaryPostTagId: number;
  link: string;
  uri: string;
}

export interface PrimarySection {
  name: string;
  primarySectionId: number;
  link: string;
  uri: string;
}

interface PrimaryTopic {
  name: string;
  primaryTopicId: number;
}

export interface Section {
  name: string;
  sectionId: number;
  slug: string;
}

export enum PageType {
  NO_TYPE = '',
  ARTICLE = 'article',
  ARTICLE_V2 = 'article-v2',
  LONGFORM = 'longform',
  COMPANY = 'company',
  COMPANY_V2 = 'company-v2',
  FRANCHISE_LIST = 'franchise-list',
  FRANCHISE_LIST_SEARCH = 'franchise-list-search',
  FRANCHISE_LIST_ITEM = 'franchise-list-item',
  FRANCHISE_LIST_ITEM_COMPANY = 'franchise-list-item-company',
  FRANCHISE = 'franchise',
  PAGE = 'page',
  MAIN_HOMEPAGE = 'mainHomepage',
  HOMEPAGE = 'homepage',
  SECTION = 'section',
  SPONSORED_PAGE = 'sponsored-page',
  STATIC_PAGE = 'static-page',
  SUBSCRIPTIONS = 'subscriptions',
  TOPIC = 'topic',
  TAG = 'tag',
  MAGAZINE = 'magazine',
  PACKAGE = 'package',
  PACKAGES = 'packages',
  AUTHOR = 'author',
  RANKINGS = 'rankings',
  HUB = 'hub',
  GUIDE_HUB = 'guide hub',
  GUIDE = 'guide',
  EVERGREEN = 'evergreen',
  LATEST = 'latest',
  PERSON = 'person',
  EDU_CHILD_RANKING_LIST = 'edu-child-rankings-list',
  EDU_RANKING_LIST = 'edu-rankings-list',
  EDU_SCHOOL = 'edu-school',
  EDU_CATEGORY = 'edu-category',
  SITEMAP = 'sitemap',
  SITEMAP_HOME = 'sitemap-home',
}

/**
 * This properties are expected on every page component
 * to support integrations
 */
export interface Page {
  adTags: { adtagname: string; description: string; slug: string }[];
  authors: Author[];
  authorNames: Array<string>;
  canonicalUrl: string;
  channel: string;
  databaseId: number;
  dateGmt: string;
  description: string;
  faq: Faq[];
  image: MediaItem | string | null;
  imageTwitter?: MediaItem | string | null;
  keywords: string;
  link: string;
  metaTitle: string;
  modifiedGmt: string;
  pageId?: number;
  pageType: PageType;
  premiumCategory: PremiumCategory;
  primarySection: PrimarySection;
  socialDescription: string;
  socialTitle: string;
  sectionNames: Array<string>;
  sections: Section[];
  tagNames: Array<string>;
  tags: {
    name: string;
    tagId: number;
  }[];
  thumbnailUrl: string;
  title: string;
  titlePrefix?: string;
  topicNames: Array<string>;
  topics: { name: string; slug: string }[];
  url: string;
  platformCD: string;
  featuredMediaType?: string;
  relatedVideoType?: string;
  widgetCss?: string | null;
  prefixedDatabaseId?: string | null;
}

/**
 * This properties are specific to Longform & Article
 * posts
 */
export interface Post extends Page {
  audienceDek: string;
  bypassPaywall: BypassPaywallResponse;
  content: string;
  dek: string;
  id: string;
  postId: number;
  postSettings: PostSettings;
  primaryPostTag: PrimaryPostTag;
  primaryTopic: PrimaryTopic;
  showModifiedTimestamp: boolean;
}
export interface Person {
  education: string;
  image: string;
  name: string;
  position: string;
}

export interface PersonPage extends Page {
  person: Person;
}
