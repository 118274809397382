/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext, useEffect } from 'react';
import { UserContext } from 'providers/UserProvider';
import { AD_TAGS } from 'components/PaidPlacement';

export interface StnVideoProps {
  dataCy?: string;
  videoId: string;
  adTags?: {
    adtagname: string;
    description: string;
    slug: string;
  }[];
}

const StnVideo = ({ videoId, adTags, dataCy = '' }: StnVideoProps) => {
  const { hasConsent } = useContext(UserContext);
  const updateStnCid = adTags?.find((tag) => tag.adtagname === AD_TAGS.NO_VIDEO_ADS);
  const updatedVideoId = updateStnCid ? videoId.replace(/-14497$/g, '-16481') : videoId;

  useEffect(() => {
    let script: any;

    if (hasConsent) {
      script = document.createElement('script');
      script.src = `https://embed.sendtonews.com/player3/embedcode.js?SC=${updatedVideoId}`;
      script.async = true;
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [hasConsent, updatedVideoId]);

  return hasConsent ? (
    <div
      data-cy={dataCy}
      className={`stn-video-player s2nPlayer k-${updatedVideoId}`}
      data-type='float'
    />
  ) : null;
};

export default StnVideo;
