import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';
import { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { bodyFonts, bodyWeight } from 'styles/typography/typeFonts';
import { BodySizes, BreakpointValues, ResponsiveStyling, ThemeProps } from './types';

const getBodyStyle =
  ($fontFamily: keyof typeof bodyFonts, size: ResponsiveStyling<keyof BodySizes>, $weight?: keyof typeof bodyWeight) =>
    ({ theme: themeProps }: { theme: ThemeProps }) => {
      const { bodyTextFonts, defaultBodyTextStyles } = themeProps.typography.bodyText;
      const bodyFontFamily = bodyTextFonts[$fontFamily];

      const { default: defaultSize, ...customSizes } = size || {};
      const breakpoints = orderBreakpoints(customSizes);

      if (!bodyFontFamily) return '';

      return css`
      ${defaultBodyTextStyles};
      ${bodyFontFamily.custom};
      font-size: ${bodyFontFamily.sizes[defaultSize]};
      ${$weight && bodyFontFamily.weights ? `font-weight: ${bodyFontFamily.weights[$weight]};` : ''};
      ${Object.keys(breakpoints).map(
    (bkp) => css`
          @media (${breakpoint[bkp]}) {
            font-size: ${bodyFontFamily.sizes[breakpoints[bkp as BreakpointValues] as keyof BodySizes]};
          }
        `,
  )}
    `;
    };

const getColor =
  (value: keyof ThemeProps['colors']) =>
    ({ theme: { colors } }: { theme: ThemeProps }) =>
      colors[value];

const themeMethods = {
  getBodyStyle,
  getColor,
};

export default themeMethods;
