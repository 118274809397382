import { color } from 'styles/globals';
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const InfoIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    fill='none'
    aria-label='Info Icon'
    viewBox='0 0 14 14'
  >
    <path
      fill={`${color.primaryBlack}`}
      d='M6 5h1.333V3.667H6m.667 8.666A5.34 5.34 0 011.333 7a5.34 5.34 0 015.334-5.333A5.34 5.34 0 0112 7a5.34 5.34 0 01-5.333 5.333zm0-12a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334m-.667 10h1.333v-4H6v4z'
    />
  </svg>
);
