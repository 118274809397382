import DevLogger from './devLogger';
import Logger from './logger';
import ProdLogger from './prodLogger';

/**
 * logger used to log messages
 * Should only be used on the BACKEND
 */
const logger: Logger = process.env.NODE_ENV === 'development' ? new DevLogger() : new ProdLogger();

/**
 * Function used to send log messages to the backend for logging
 * Should only be used on the FRONTEND
 */
export const logToBackend = (type: string, name: string, params: Record<string, any>, duration: number = 0) => {
  fetch('/api/log', {
    body: JSON.stringify({
      duration,
      name,
      params,
      type,
    }),
    method: 'POST',
  })
    .then(() => {})
    .catch(() => {});
};

export default logger;
