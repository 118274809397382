import React from 'react';
import styled, { css, Interpolation } from 'styled-components';
import { color } from 'styles/globals';

export const loadingBackgroundSize = '600px';

export const loadingBackground = css`
  animation: opacity 2.5s both;
  background-color: ${color.lightGray};
  background-image: linear-gradient(
    90deg,
    ${color.darkGray}00 20%,
    ${color.darkGray}1A 50%,
    ${color.darkGray}00 80%
  );
  background-size: ${loadingBackgroundSize};
  background-position: 0 0;

  @keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const Wrapper = styled.div<{ $size: Interpolation<React.CSSProperties> }>`
  ${({ $size }) => $size}
  ${loadingBackground};
`;

export default Wrapper;
