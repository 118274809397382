/* eslint-disable react/no-array-index-key */

import cx from 'classnames';
import * as S from './ResponsiveTableStyles';

const ResponsiveTable = ({
  headers,
  rows,
  caption,
  hasLink,
}: {
  headers: string[];
  rows: string[][];
  caption: string;
  hasLink: boolean;
}) => {
  const colNumber = headers.length;
  const isLastColumnNotLink = !hasLink;

  return (
    <S.ResponsiveTableWrapper>
      <S.ResponsiveTableDesktop>
        <thead>
          <tr>
            {headers.map((header, hIdx) => (
              <th
                key={`${header}${hIdx}`}
                scope='col'
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rIdx) => (
            <tr key={rIdx}>
              {row.map((cell, cIdx) =>
                (cIdx === 0 ? (
                  <th
                    key={`${cell}${cIdx}${rIdx}`}
                    scope='row'
                  >
                    {cell}
                  </th>
                ) : (
                  <td
                    key={`${cell}${cIdx}${rIdx}`}
                    className={cx({ 'last-cell': cIdx === row.length - 1 })}
                  >
                    {cell}
                  </td>
                )))}
            </tr>
          ))}
        </tbody>
      </S.ResponsiveTableDesktop>
      <S.ResponsiveTableMobile $colNumber={isLastColumnNotLink ? colNumber + 1 : colNumber}>
        {caption && (
          <thead>
            <tr>
              <th colSpan={colNumber}>{caption}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((cells, rIdx) =>
            cells.map((cell, cIdx) => {
              const remappedColumnIndex = isLastColumnNotLink ? -1 : colNumber - 1;

              return (
                !(cIdx === remappedColumnIndex) && (
                  <tr key={`${rIdx}${cIdx}`}>
                    {cIdx === 0 ? (
                      <>
                        <th>{cell}</th>
                        <th className={cx('last-cell')}>{cells[remappedColumnIndex]}</th>
                      </>
                    ) : (
                      <>
                        <th
                          className='header'
                          scope='row'
                        >
                          {headers[cIdx]}
                        </th>
                        <td>{cell}</td>
                      </>
                    )}
                  </tr>
                )
              );
            }))}
        </tbody>
      </S.ResponsiveTableMobile>
    </S.ResponsiveTableWrapper>
  );
};

export default ResponsiveTable;
