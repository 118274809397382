import { CloseIconWhite } from 'icons/CloseIconWhite';
import { Logo } from 'icons/Logo';
import Button from 'components/Globals/Base/Button';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PersonalizationContext } from 'providers/PersonalizationProvider';
import { StickyInhouseAdProps } from 'interfaces/ads/InhouseAd';
import { PageType } from 'interfaces/content/articles/Post';
import { InhouseAdTrackingObject } from 'services/Gtm/entities';
import { pushToDataLayer } from 'services/Gtm/functions';
import StickyFooterForm from './StickyFooterForm';
import * as S from './StickyFooterStyles';

interface StickyAdFooterProps {
  pageType: PageType;
  currentSection: string;
  currentTags: string;
  currentAuthors: string;
}

const THEME = {
  'theme-blue': {
    primary: '#54669f',
    secondary: '#eaedf3',
  },
  'theme-yellow': {
    primary: '#9F8F54',
    secondary: '#F3F1EA',
  },
};

const StickyAdFooter = ({ pageType, currentSection, currentTags, currentAuthors }: StickyAdFooterProps) => {
  const [isClosed, setIsClosed] = useState(false);
  const [inhouseAd, setInhouseAd] = useState<StickyInhouseAdProps | null>(null);

  const { performAdUpdates: performInhouseAdUpdates, getAdForSlot: getInhouseAdForSlot } =
    useContext(PersonalizationContext);

  useEffect(() => {
    if (pageType && currentSection && currentTags && currentAuthors) {
      // eslint-disable-next-line no-void
      void performInhouseAdUpdates(pageType, 'Sticky', currentSection, currentTags, currentAuthors);
    }
  }, [pageType, currentSection, currentTags, currentAuthors]);

  useEffect(() => {
    // search for ad in persisted ads
    const adData = getInhouseAdForSlot(pageType, 'Sticky', 1);
    setInhouseAd(adData as StickyInhouseAdProps);
  }, [getInhouseAdForSlot, pageType]);

  const getTrackingEvent = useCallback(
    (event: string): InhouseAdTrackingObject => ({
      adId: inhouseAd!.adId,
      event,
      placementId: `${pageType}-Sticky`,
      ...inhouseAd!.tracking,
    }),
    [inhouseAd],
  );

  useEffect(() => {
    if (!inhouseAd) return;
    // Ad load event
    // This does not count as an impression
    // In fact, this helps us find out how many ad displays
    // in page have actually generated impressions
    pushToDataLayer(getTrackingEvent('fortune.inhouseAd.request'));
  }, [inhouseAd, getTrackingEvent]);

  const handleClick = () => {
    setIsClosed(true);
  };

  if (isClosed || !inhouseAd) {
    return null;
  }

  // analytics impressions & clicks should not count for this one; only nl_subscribe / nl_unsubscribe

  return (
    <S.StickyFooterContainer className={inhouseAd?.newsletterId || ''}>
      <S.LogoWrapper color={THEME[inhouseAd?.theme]?.primary}>
        <div className='fortune-nl-logo'>
          <Logo />
          Newsletters
        </div>
        <div className='close-icon'>
          <Button
            onClick={handleClick}
            hasRightArrow={false}
            variant='tertiary'
            size={{ default: 'small' }}
          >
            <CloseIconWhite />
          </Button>
        </div>
      </S.LogoWrapper>
      <S.StickyFooterContent>
        <S.Title>
          {inhouseAd?.title}
          <div className='line' />
        </S.Title>
        <S.Description>{inhouseAd?.description}</S.Description>
        <StickyFooterForm
          consent={inhouseAd?.consent || ''}
          marketingConsent={inhouseAd?.marketingConsent || ''}
          submitButton={inhouseAd?.submitButton || ''}
        />
      </S.StickyFooterContent>
    </S.StickyFooterContainer>
  );
};

export default StickyAdFooter;
