import styled from 'styled-components';
import { breakpoint, color } from 'styles/globals';

interface props {
  src: string;
  width: string;
  height: string;
  dockingMode: boolean;
}

interface DockingModeProps {
  $dockingMode: boolean;
}

export const Wrapper = styled.div`
  display: block;
  height: 100% !important;
  padding: 28.15%;
  position: relative;
  width: 100% !important;

  @media (${breakpoint.mdMin}) {
    width: 480px !important;
    height: 270px !important;
    margin-left: -80px !important;
  }

  @media (${breakpoint.lgMin}) {
      width: 720px !important;
      height: 405px !important;
      margin-left: -230px !important;
    }

  @media (${breakpoint.hybridMin}) {
      width: 720px !important;
      height: 405px !important;
      margin-left: -200px !important;
    }

  @media (${breakpoint.xlMin}) {
    width: 768px !important;
    height: 432px !important;
    margin-left: -220px !important;
  }

  > div {
    margin: 0!important;
  }
`;

export const StyledVideo = styled.video<props>`
 

  ${({ dockingMode }) =>
    dockingMode &&
    `
  width: 300px;
  height: 169px;
  position: fixed;
  left: auto;
  bottom: 70px;
  right: 16px;
  z-index: 10000;
  src: ${(props: props) => props.src};
  margin:auto;
  `}
`;

export const CloseButton = styled.button<DockingModeProps>`
  ${({ $dockingMode }) =>
    $dockingMode &&
    `
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10001;
    borderColor: ${color.primaryWhite};
  `}

  ${({ $dockingMode }) =>
    !$dockingMode &&
    `
    display: none;
  `}
`;

export const Container = styled.div<DockingModeProps>`
  ${({ $dockingMode }) =>
    $dockingMode &&
    `
    width: 300px;
    height: 169px;
    position: fixed;
    left: auto;
    bottom: 70px;
    right: 16px;
    z-index: 10000;
  `}

  ${({ $dockingMode }) =>
    !$dockingMode &&
    `
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  `}
`;

export const CloseIconContainer = styled.span`
  & > svg {
    fill: ${color.primaryBlack};
    height: 16px;
    width: 16px;
    textAlign: center;
    verticalAlign: middle;
    margin-top: 10%;
  }
`;
