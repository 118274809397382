import React from 'react';
import getCommonTestId from 'constants/testsIds/common';
import { RightRailArticlesStickyContainer, RightRailArticlesStickyAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface RightRailFlexArticlesProps {
  id: number;
  stickyTop?: number | null;
  dataCy?: string;
}

const RightRailFlexArticles: React.FC<RightRailFlexArticlesProps> = ({
  id,
  stickyTop,
  dataCy = '',
}: RightRailFlexArticlesProps) => (
  <RightRailArticlesStickyContainer data-cy={getCommonTestId('RIGHT_RAIL_STICKY_AD')}>
    <RightRailArticlesStickyAd
      $stickyTop={stickyTop}
      data-cy={dataCy}
    >
      <AdSlotToggle
        placementName='RightRailFlex_Articles'
        index={id + 1}
        slotId={`RightRailFlex_Articles${id}`}
      />
    </RightRailArticlesStickyAd>
  </RightRailArticlesStickyContainer>
);

export default RightRailFlexArticles;
