import { RecommendsFooterNav, RecommendsFooterNavLink } from 'interfaces/navigation/RecommendsFooter';
import * as S from 'components/RecommendsFooter/RecommendsFooterStyles';
import Link from 'components/Globals/Base/Link';
import { SocialItem, SocialList } from 'components/SocialList';
import RawHtml from 'utils/miscUtils/rawHtml';
import Accordion from 'components/Globals/Accordion';
import { useState } from 'react';
import { Hub } from 'interfaces/Home';

interface FooterBottomProps {
  socialList: SocialItem[];
  disclaimer: string;
}

export interface RecommendsFooterProps extends FooterBottomProps {
  footer: RecommendsFooterNav;
  currentSection?: Hub;
}

interface SectionLinkListProps {
  sectionLinks: RecommendsFooterNavLink[] | null;
}

interface FooterSectionProps extends SectionLinkListProps {
  sectionInfo: { label: string; url: string | null };
}

const FooterBottomSection = ({ socialList, disclaimer }: FooterBottomProps) => (
  <S.FooterBottomWrapper>
    <SocialList items={socialList} />
    <S.FooterDisclaimer>{RawHtml({ html: disclaimer })}</S.FooterDisclaimer>
  </S.FooterBottomWrapper>
);

const SectionLinkList = ({ sectionLinks }: SectionLinkListProps) => (
  <ul>
    {sectionLinks?.map((navLink) => (
      <li key={navLink.id}>
        <Link
          href={navLink.url || ''}
          type='brandGray'
          trackingData={{
            contentPlacementCD: 'footer',
            eventAction: 'footer links',
            eventCategory: 'navigation',
            eventLabel: navLink.label,
          }}
          ariaLabel={`footer link to ${navLink.label}`}
        >
          {navLink.label}
        </Link>
      </li>
    ))}
  </ul>
);

const FooterDesktopSection = ({ sectionInfo, sectionLinks }: FooterSectionProps) => (
  <S.FooterDesktopSectionWrapper>
    <Link
      href={sectionInfo.url || ''}
      className='section-title'
      type='brandAccentPrimary'
      trackingData={{
        contentPlacementCD: 'footer',
        eventAction: 'footer links',
        eventCategory: 'navigation',
        eventLabel: sectionInfo.label,
      }}
      ariaLabel={`footer link to ${sectionInfo.label}`}
    >
      {sectionInfo.label}
    </Link>
    <SectionLinkList sectionLinks={sectionLinks} />
  </S.FooterDesktopSectionWrapper>
);

const RecommendsFooter = ({ currentSection, footer, socialList, disclaimer }: RecommendsFooterProps) => {
  const [expandedSections, setExpandedSections] = useState<boolean[]>([]);

  const getSectionParentSlug = (parent: Hub['parent']): string | undefined => {
    const sectionParent = parent?.parent;
    return sectionParent ? getSectionParentSlug(sectionParent) : parent?.slug;
  };

  const sections = () => {
    if (currentSection) {
      const currentSectionParentSlug = getSectionParentSlug(currentSection);
      if (currentSectionParentSlug) {
        const footerSection = footer.find((section) => section.url?.includes(currentSectionParentSlug));
        if (!footerSection) return footer;

        const aboutUsSection = footer.find((section) => section.url?.includes('about-us'));
        return aboutUsSection ? [...(footerSection.subMenu || []), aboutUsSection] : footerSection.subMenu;
      }
      return footer;
    }
    return footer;
  };

  const toggleSections = (index: number) => {
    setExpandedSections((prev) => {
      const updatedSections = [...prev];
      updatedSections[index] = !updatedSections[index];
      return updatedSections;
    });
  };

  return (
    <>
      <S.FooterWrapperDesktop id='recommends-footer-desktop'>
        <nav>
          {sections()?.map((section) => (
            <FooterDesktopSection
              key={section.id}
              sectionInfo={{ label: section.label, url: section.url }}
              sectionLinks={section.subMenu}
            />
          ))}
        </nav>
        <FooterBottomSection
          socialList={socialList}
          disclaimer={disclaimer}
        />
      </S.FooterWrapperDesktop>
      <S.FooterWrapperMobileAndTablet id='recommends-footer-mobile'>
        <nav>
          {sections()?.map((section, index) => (
            <Accordion
              key={section.id}
              index={index}
              isExpanded={expandedSections[index]}
              onClick={() => toggleSections(index)}
              title={section.label}
            >
              <SectionLinkList sectionLinks={section.subMenu} />
            </Accordion>
          ))}
        </nav>
        <FooterBottomSection
          socialList={socialList}
          disclaimer={disclaimer}
        />
      </S.FooterWrapperMobileAndTablet>
    </>
  );
};

export default RecommendsFooter;
