/* eslint-disable sort-keys-fix/sort-keys-fix */
import { OutputsInfo } from 'interfaces/RecommendsWidget';
import { color } from 'styles/globals';

export const BLURRED_GRAY_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8fv1mPQAIHAMIsIR6agAAAABJRU5ErkJggg==';

export const layoutCount = {
  author: 15,
  latest: 15,
  theLatest: 45,
  magazine: 12,
  newSavingsWidgetMobile: 2,
  newSavingsWidgetDesktop: 3,
  packages: 15,
  section: 30,
  tag: 15,
  topic: 15,
  companyDirectory: 30,
  latestArticles: 20,
  latestHeroArticles: 10,
  eduSubsectionLatestArticles: 12,
  sitemap: 84,
};

export const recommendsLayoutCounts = {
  homepageLatestArticlesPerPage: 16,
  itemsPerSection: 4,
  latestArticlesHero: 5,
  topPicksHero: 5,
  topArticlesSection: 10,
  subsectionArticlePreviews: 8,
};

export const educationLayoutCounts = {
  itemsPerSectionPreview: 4,
  sectionLatest: 20,
  subsectionLatest: 12,
  educationSectionLatest: 12,
};

export const mediaItem = {
  altText: 'Fortune',
  caption: 'Fortune',
  mediaItemUrl: 'https://content.fortune.com/wp-content/uploads/2020/09/placeholder_16x9.jpg?resize=800,450',
};

export const cryptoRecommendsSubdomains = ['crypto', 'recommends'] as (string | undefined)[];

export const crashCourseLandingPageFilters = [
  {
    key: 'complexity',
    options: [
      {
        checked: false,
        title: 'Beginner',
        value: 'beginner',
      },
      {
        checked: false,
        title: 'Intermediate',
        value: 'intermediate',
      },
      {
        checked: false,
        title: 'Advanced',
        value: 'advanced',
      },
    ],
    title: 'Term Complexity Level',
  },
];

export const citiesRankingListSlugs = ['best-places-families'];

// The order of this object keys needs to be preserved because in the same order the information
// is displayed on the Recommends widget
export const cdRatesInfo: OutputsInfo = {
  featured: {
    info: 'Offers featured here have been sponsored by our partners, which impacts how, where, and in what order the products appear.',
    title: 'FEATURED OFFERS',
  },
  apy: {
    info: 'APY = Annual Percentage Yield',
    title: 'APY',
  },
  term: {
    info: '',
    title: 'TERM',
  },
  minDeposit: {
    info: '',
    title: 'MIN. DEPOSIT',
  },
  estEarnings: {
    info: 'Interest earned calculation based on a $$DEPOSIT_AMOUNT$ deposit amount held for a period of 1 year and is rounded to the nearest dollar. The estimation is provided by Bankrate and assumes no fees are accrued, the APY does not change, and minimum balance requirements are met. Actual earnings may vary.',
    title: 'EST. EARNINGS',
    placement: 'bottom-left',
  },
};

// The order of this object keys needs to be preserved because in the same order the information
// is displayed on the Recommends widget
export const savingsAccountRatesInfo: OutputsInfo = {
  featured: {
    info: 'Offers featured here have been sponsored by our partners, which impacts how, where, and in what order the products appear.',
    title: 'FEATURED OFFERS',
  },
  apy: {
    info: 'APY = Annual Percentage Yield',
    title: 'APY',
  },
  minBalance: {
    info: '',
    title: 'MIN. BALANCE FOR APY',
  },
  estEarnings: {
    info: 'Interest earned calculation based on a $$DEPOSIT_AMOUNT$ deposit amount held for a period of 1 year and is rounded to the nearest dollar. The estimation is provided by Bankrate and assumes no fees are accrued, the APY does not change, and minimum balance requirements are met. Actual earnings may vary.',
    title: 'EST. EARNINGS',
    placement: 'bottom-left',
  },
};

export const savingsAccountTypes = [
  { name: 'Savings & MMAs', value: 'MMA,SAVINGS' },
  { name: 'MMAs Only', value: 'MMA' },
  { name: 'Savings Only', value: 'SAVINGS' },
];

export const formatTermLength = (termLength: number): string => {
  const years = Math.round((termLength / 12) * 10) / 10;
  if (years < 1) {
    return `${termLength} months`;
  }
  if (years === 1) {
    return `${years} year`;
  }
  if (years > 1 && years < 2) {
    return `${termLength} months`;
  }
  return `${years} years`;
};

export const minTermLengths = [1, 6, 12, 18, 24, 36].map((term) => ({
  name: formatTermLength(term),
  value: term,
}));

export const maxTermLengths = [1, 6, 12, 18, 24, 36, 48, 60, 72].map((term) => ({
  name: formatTermLength(term),
  value: term,
}));

export const topicNavBorderColor = {
  asia: `${color.primaryRed}`,
  crypto: `${color.cryptoGold}`,
  education: `${color.recommendsGreen}`,
  europe: `${color.primaryRed}`,
  own: `${color.primaryRed}`,
  recommends: `${color.recommendsGreen}`,
  well: `${color.wellBlue}`,
} as { [key: string]: string };

export const subdomainColors = {
  crypto: `${color.cryptoGold}`,
  own: `${color.primaryBlack}`,
  recommends: `${color.recommendsDarkGreen}`,
  well: `${color.wellBlue}`,
} as { [key: string]: string };

export enum RedVentureWidgets {
  SAVINGS_MMA = '5c6ba399-d32f-4e30-9722-8373c50873c3',
  CD_RATES = 'ed375ef3-c83b-43c1-8a5a-57347a4e9d42',
}

// values for the microsites slugs
export const Microsites = {
  Asia: 'asia',
  Crypto: 'crypto',
  Education: 'education',
  Europe: 'europe',
  Recommends: 'recommends',
  Well: 'well',
  Own: 'own',
};

export const healthSection = 'Health';

export type MicrositeType = (typeof Microsites)[keyof typeof Microsites];

// values for the microsites names
export const MicrositeNames = {
  [Microsites.Asia]: 'Asia',
  [Microsites.Crypto]: 'Crypto',
  [Microsites.Education]: 'Education',
  [Microsites.Europe]: 'Europe',
  [Microsites.Recommends]: 'Recommends',
  [Microsites.Well]: 'Well',
} as { [key: string]: string };

export const PaidModules = {
  ExecutiveProfiles: 'executive_profiles',
  Nativo: 'nativo_rail',
  Twitter: 'twitter_feed',
} as { [key: string]: string };

export const CouponLinkList: Map<string, string> = new Map([
  ['acer-inc', 'https://coupons.fortune.com/acer'],
  ['adidas', 'https://coupons.fortune.com/adidas'],
  ['advance-auto-parts', 'https://coupons.fortune.com/advance-auto-parts'],
  ['alibaba-group-holding', 'https://coupons.fortune.com/aliexpress'],
  ['allbirds', 'https://coupons.fortune.com/allbirds'],
  ['american-airlines-group', 'https://coupons.fortune.com/american-airlines'],
  ['american-eagle-outfitters', 'https://coupons.fortune.com/american-eagle'],
  ['apple', 'https://coupons.fortune.com/apple'],
  ['athleta', 'https://coupons.fortune.com/athleta'],
  ['att', 'https://coupons.fortune.com/att-wireless'],
  ['autodesk', 'https://coupons.fortune.com/autodesk'],
  ['autozone', 'https://coupons.fortune.com/autozone'],
  ['avis-budget-group', 'https://coupons.fortune.com/avis'],
  ['away', 'https://coupons.fortune.com/away'],
  ['belk', 'https://coupons.fortune.com/belk'],
  ['best-buy', 'https://coupons.fortune.com/best-buy'],
  ['bjs-wholesale-club', 'https://coupons.fortune.com/bjs-wholesale'],
  ['booking-holdings', 'https://coupons.fortune.com/bookingcom'],
  ['canon', 'https://coupons.fortune.com/canon'],
  ['carters', 'https://coupons.fortune.com/carters'],
  ['chegg', 'https://coupons.fortune.com/chegg'],
  ['chewy', 'https://coupons.fortune.com/chewy'],
  ['columbia-sportswear', 'https://coupons.fortune.com/columbia'],
  ['comcast', 'https://coupons.fortune.com/peacock-tv'],
  ['costco', 'https://coupons.fortune.com/costco'],
  ['crocs', 'https://coupons.fortune.com/crocs'],
  ['customink', 'https://coupons.fortune.com/custom-ink'],
  ['dell-technologies', 'https://coupons.fortune.com/dell'],
  ['dicks-sporting-goods', 'https://coupons.fortune.com/dicks-sporting-goods'],
  ['directv', 'https://coupons.fortune.com/directv'],
  ['dominos-inc', 'https://coupons.fortune.com/dominos'],
  ['doordash', 'https://coupons.fortune.com/doordash'],
  ['dsw', 'https://coupons.fortune.com/dsw'],
  ['ebay', 'https://coupons.fortune.com/ebay'],
  ['emirates-group', 'https://coupons.fortune.com/emirates'],
  ['essilorluxottica', 'https://coupons.fortune.com/lenscrafters'],
  ['expedia-group', 'https://coupons.fortune.com/expedia'],
  ['express', 'https://coupons.fortune.com/express'],
  ['fabletics', 'https://coupons.fortune.com/fabletics'],
  ['fedex', 'https://coupons.fortune.com/fedex'],
  ['ferguson', 'https://coupons.fortune.com/buildcom'],
  ['finish-line', 'https://coupons.fortune.com/finish-line'],
  ['gamestop', 'https://coupons.fortune.com/gamestop'],
  ['gap', 'https://coupons.fortune.com/gap'],
  ['genesco', 'https://coupons.fortune.com/journeys'],
  ['godaddy', 'https://coupons.fortune.com/godaddy'],
  ['goodyear-tire-rubber', 'https://coupons.fortune.com/goodyear'],
  ['graham-holdings-company', 'https://coupons.fortune.com/kaplan-test-prep'],
  ['groupon-inc', 'https://coupons.fortune.com/groupon'],
  ['grubhub', 'https://coupons.fortune.com/grubhub'],
  ['h-m-hennes-mauritz', 'https://coupons.fortune.com/cos'],
  ['harbor-freight-tools', 'https://coupons.fortune.com/harbor-freight'],
  ['hertz-global-holdings', 'https://coupons.fortune.com/hertz'],
  ['home-depot', 'https://coupons.fortune.com/home-depot'],
  ['hp', 'https://coupons.fortune.com/hp'],
  ['hr-block', 'https://coupons.fortune.com/hr-block'],
  ['ikea', 'https://coupons.fortune.com/ikea'],
  ['instacart', 'https://coupons.fortune.com/instacart'],
  ['intuit-inc', 'https://coupons.fortune.com/quickbooks'],
  ['irobot', 'https://coupons.fortune.com/irobot'],
  ['j-c-penney', 'https://coupons.fortune.com/jcpenney'],
  ['jcrew-group', 'https://coupons.fortune.com/j-crew'],
  ['joann', 'https://coupons.fortune.com/joann'],
  ['kohls', 'https://coupons.fortune.com/kohls'],
  ['l-l-bean', 'https://coupons.fortune.com/ll-bean'],
  ['la-z-boy-incorporated', 'https://coupons.fortune.com/la-z-boy'],
  ['lego', 'https://coupons.fortune.com/lego'],
  ['lenovo-group', 'https://coupons.fortune.com/lenovo'],
  ['lowes', 'https://coupons.fortune.com/lowes'],
  ['lululemon-athletica', 'https://coupons.fortune.com/lululemon'],
  ['macys', 'https://coupons.fortune.com/macys'],
  ['mars', 'https://coupons.fortune.com/mms'],
  ['mattress-firm-holding', 'https://coupons.fortune.com/mattressfirm'],
  ['michael-kors-holdings', 'https://coupons.fortune.com/michael-kors'],
  ['nike', 'https://coupons.fortune.com/converse'],
  ['nike', 'https://coupons.fortune.com/nike'],
  ['nordstrom', 'https://coupons.fortune.com/nordstrom'],
  ['office-depot', 'https://coupons.fortune.com/office-depot'],
  ['old-navy', 'https://coupons.fortune.com/old-navy'],
  ['party-city-holdco', 'https://coupons.fortune.com/party-city'],
  ['petco-health-and-wellness', 'https://coupons.fortune.com/petco'],
  ['priceline-com-inc', 'https://coupons.fortune.com/priceline'],
  ['puma', 'https://coupons.fortune.com/puma'],
  ['recreational-equipment-inc-rei', 'https://coupons.fortune.com/rei'],
  ['revzilla-com', 'https://coupons.fortune.com/revzilla'],
  ['sally-beauty-holdings', 'https://coupons.fortune.com/sally-beauty'],
  ['sams-club', 'https://coupons.fortune.com/sams-club'],
  ['samsung-electronics', 'https://coupons.fortune.com/samsung'],
  ['shutterfly', 'https://coupons.fortune.com/shutterfly'],
  ['skechers-u-s-a', 'https://coupons.fortune.com/skechers'],
  ['sony', 'https://coupons.fortune.com/sony'],
  ['staples-inc', 'https://coupons.fortune.com/print-staples'],
  ['staples', 'https://coupons.fortune.com/staples'],
  ['steelcase', 'https://coupons.fortune.com/steelcase'],
  ['target', 'https://coupons.fortune.com/target'],
  ['the-childrens-place-retail-stores-inc', 'https://coupons.fortune.com/childrens-place'],
  ['the-mens-wearhouse-inc', 'https://coupons.fortune.com/mens-wearhouse'],
  ['the-talbots-inc', 'https://coupons.fortune.com/talbots'],
  ['tractor-supply', 'https://coupons.fortune.com/tractor-supply'],
  ['uber-technologies', 'https://coupons.fortune.com/uber'],
  ['udemy', 'https://coupons.fortune.com/udemy'],
  ['ulta-beauty', 'https://coupons.fortune.com/ulta'],
  ['under-armour', 'https://coupons.fortune.com/under-armour'],
  ['ups', 'https://coupons.fortune.com/ups-my-choice'],
  ['valvoline', 'https://coupons.fortune.com/valvoline'],
  ['verizon', 'https://coupons.fortune.com/verizon'],
  ['vf', 'https://coupons.fortune.com/vans'],
  ['victorias-secret', 'https://coupons.fortune.com/victorias-secret'],
  ['walgreen-co', 'https://coupons.fortune.com/walgreens'],
  ['walmart', 'https://coupons.fortune.com/walmart'],
  ['wayfair', 'https://coupons.fortune.com/wayfair'],
  ['zoro', 'https://coupons.fortune.com/zoro'],
]);

// Marketing Sales Page email subject and body
export const marketingEmailSubject = `?subject=${encodeURIComponent('Activate my Fortune company page')}`;
export const marketingEmailBody = `&body=${encodeURIComponent(
  'I want to receive more information on how to Activate my Premium Company Page on Fortune. Thank you.',
)}`;

// values used for section types
export enum SectionTypes {
  SECTION,
  SUBSECTION,
  TOPIC,
}

// value used in Company Directory -> Filters
export const STEP = 500;

// used for SEO purpose
export const PAGES_COUNT_LIMIT = 100;

export const SectionTitles = {
  moreArticles: 'More Articles',
  latestArticles: 'Latest Articles',
  latestEducationArticles: 'Latest Education Articles',
  topPicks: 'Top Picks',
  topArticles: 'Top Articles',
};

// Types of pages to generate in Recommends Sitemap
export const SitemapTypes = {
  roundups: 'roundups',
  reviews: 'reviews',
  articles: 'articles',
};

// Titles for pages to generated in Recommends Sitemap
export const SitemapTypesTitles = {
  roundups: 'Rankings and Roundups',
  reviews: 'Reviews',
  articles: 'Articles',
};

// Edu Archer Ads Template
export const ArcherAdsTemplate = 'recommends.archer-ads';
