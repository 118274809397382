/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from 'components/Globals/Base/Button';
import Link from 'components/Globals/Base/Link';
import MyAccount from 'components/MyAccount';
import useBreakpoint from 'hooks/useBreakpoint';
import { Logo } from 'icons/Logo';
import { UserContext } from 'providers/UserProvider';
import React, { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import showLogin from 'services/Piano/showLogin';
import { trackEvent } from 'services/Gtm/functions';
import { useRouter } from 'next/router';
import { Microsites, topicNavBorderColor } from 'constants/constants';
import DisclosureStripe, { DisclosureStripeProps } from 'components/DisclosureStripe';
import Placeholder from 'components/Placeholder';
import TopicNavigation from 'components/TopicBasedNavigation';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { DrawerToggleButton } from 'components/Header/HeaderStyles';
import ProgressBar from 'components/recommends/ProgressBar';
import BackToFortune from 'components/recommends/BackToFortune';
import * as S from './NavigationBarStyles';

export interface NavigationBarProps {
  domainLogo: string | null;
  subDomain: string;
  topics: MenuItemProps[];
  showNewTopicNav: boolean;
  id?: string;
  disclosureProps?: DisclosureStripeProps;
  progressBar?: boolean;
}
// eslint-disable-next-line react/no-danger, max-len
const renderDomainLogo = (html?: string | null) => html && <div dangerouslySetInnerHTML={{ __html: html }} />;

export const showInstreamAd = (micrositeDomain: string | undefined) => {
  googletag
    .defineSlot(
      `/21809533738/${Microsites.Well === micrositeDomain ? 'fortune.well' : 'fortune'}/search/advancedsearch/instream`,
      [
        [728, 90],
        [300, 250],
        [336, 280],
      ],
      'InstantSearch_InStream0',
    )
    ?.addService(googletag.pubads());
  googletag.pubads().refresh();
  document.getElementById('queryly_query')?.addEventListener('keydown', () => {
    googletag.pubads().refresh();
  });
};

const NavigationBar = (props: NavigationBarProps, ref: ForwardedRef<HTMLLabelElement> | null) => {
  const { domainLogo, subDomain, topics, id, disclosureProps, showNewTopicNav, progressBar } = props;
  const isMobile = useBreakpoint('mdMax');
  const isTablet = useBreakpoint('lgMax');
  const { userState: user } = useContext(UserContext);
  const router = useRouter();
  const [isSimple, setIsSimple] = useState(false);
  const isSubscribePage = router.asPath === '/subscribe/';

  const shouldRenderMyAccount = ['own', 'crypto', 'europe'].includes(subDomain);
  const shouldRenderSearch = ['own', 'well', 'europe'].includes(subDomain) && !isSubscribePage;
  const shouldRenderBars = !showNewTopicNav || isTablet;

  const trackingData = {
    contentPlacementCD: 'search hero',
    eventAction: 'search hero experiment',
    eventCategory: 'search',
  };

  const burgerTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation toggle',
    eventCategory: 'navigation',
    eventLabel: 'open',
  };

  const trackClick = (event: string) => {
    trackEvent({ ...trackingData, eventLabel: event });
  };

  const logoHref = useMemo(() => {
    if (subDomain === Microsites.Education) {
      return `/${Microsites.Recommends}/`;
    }
    if (['own', Microsites.Europe, Microsites.Asia].includes(subDomain)) return '/';
    return `/${subDomain}/`;
  }, [subDomain]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.currentTarget.click();
    }
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      if (window.scrollY < 160) {
        setIsSimple(false);
      }
      if (window.scrollY > 225 && !isMobile && !isTablet && showNewTopicNav) {
        setIsSimple(true);
      }
    };

    window.addEventListener('scroll', checkScrollPosition);

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, [id, isMobile, isTablet, showNewTopicNav, setIsSimple]);

  return (
    <S.Wrapper
      id={id}
      data-cy='header'
      $isSimple={isSimple}
      $isMobile={isMobile}
      $isTablet={isTablet}
      $showNewTopicNav={showNewTopicNav}
    >
      {isMobile && (
        <S.NavigationMobileContent key='mobile'>
          <DrawerToggleButton
            htmlFor='drawer-toggle'
            className='hamburger-toggle'
            onClick={() => {
              if (trackEvent && burgerTrackingData) trackEvent(burgerTrackingData);
            }}
            onKeyDown={handleKeyDown}
            aria-label='Open menu'
            data-cy='burger-button'
            ref={ref}
            tabIndex={0}
          >
            <span />
          </DrawerToggleButton>
          <Link
            href={logoHref}
            ariaLabel='Homepage'
            dataCy='logo'
          >
            {renderDomainLogo(domainLogo) || <Logo />}
          </Link>
        </S.NavigationMobileContent>
      )}
      {subDomain === Microsites.Recommends && <BackToFortune />}
      <S.SimpleNavWrapper $isSimple={isSimple}>
        {isMobile === false && (
          <S.NavigationContent
            className={subDomain}
            key='desktop'
            $isSimple={isSimple}
          >
            {isSimple === false && (
              <S.NavigationLeft>
                {shouldRenderBars && (
                  <DrawerToggleButton
                    htmlFor='drawer-toggle'
                    className='hamburger-toggle'
                    onClick={() => {
                      if (trackEvent && burgerTrackingData) trackEvent(burgerTrackingData);
                    }}
                    onKeyDown={handleKeyDown}
                    aria-label='Open menu'
                    data-cy='burger-button'
                    tabIndex={0}
                  >
                    <span />
                  </DrawerToggleButton>
                )}
                {shouldRenderSearch && (
                  <S.NavigationSearchWrapper
                    className={subDomain}
                    tabIndex={0}
                    role='button'
                    onClick={() => {
                      showInstreamAd(subDomain);
                      trackClick('Search');
                    }}
                    onKeyDown={() => trackClick('Search')}
                    data-cy='searchButton'
                  >
                    <label htmlFor='queryly_toggle'>SEARCH</label>
                  </S.NavigationSearchWrapper>
                )}
              </S.NavigationLeft>
            )}
            <S.NavigationCenter
              $isSimple={isSimple}
              className={subDomain}
            >
              <Link
                href={logoHref}
                ariaLabel='Homepage'
                dataCy='logo'
              >
                {renderDomainLogo(domainLogo) || <Logo />}
              </Link>
            </S.NavigationCenter>
            {shouldRenderMyAccount && (
              <S.NavigationRight className={subDomain}>
                {user.isLoggedIn === null && user.hasAccess === null && (
                  <Placeholder
                    size={{ height: 40, width: 260 }}
                    className='account-placeholder'
                  />
                )}
                {user.isLoggedIn && (
                  <MyAccount
                    className={`${subDomain} account-menu`}
                    isCrypto={Microsites.Crypto === subDomain}
                    isEurope={Microsites.Europe === subDomain}
                    dataCy='myAccountDropdown'
                  />
                )}
                {user.isLoggedIn === false && (
                  <Button
                    className='sign-in-button'
                    onClick={showLogin}
                    dataCy='signinBtnDesktop'
                    variant='tertiary'
                    hasRightArrow={false}
                  >
                    SIGN IN
                  </Button>
                )}
                {user.hasAccess === false && !isSubscribePage && (
                  <Link
                    className='subscribe-link'
                    href='/subscribe/'
                    dataCy='subscribeBtnDesktop'
                    type='accentButton'
                    hasRightArrow={false}
                  >
                    Subscribe Now
                  </Link>
                )}
              </S.NavigationRight>
            )}
          </S.NavigationContent>
        )}
        {isMobile === undefined && (
          <S.NavigationPlaceholder>
            <Placeholder
              className='menu-placeholder'
              size={{ height: 24, width: 30 }}
            />
            <Placeholder size={{ height: isMobile ? 24 : 32, width: isMobile ? 200 : 380 }} />
          </S.NavigationPlaceholder>
        )}
        {showNewTopicNav && (
          <TopicNavigation
            subdomain={subDomain}
            color={topicNavBorderColor[subDomain]}
            topics={topics}
            isSimple={isSimple}
            showNewTopicNav
          />
        )}
      </S.SimpleNavWrapper>
      {disclosureProps && (
        <DisclosureStripe
          {...disclosureProps}
          className='stickyDisclosure'
        />
      )}
      {progressBar && <ProgressBar />}
    </S.Wrapper>
  );
};

export default forwardRef(NavigationBar);
