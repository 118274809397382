/* eslint-disable max-len */
import Script from 'next/script';
import { useContext, useState } from 'react';
import CustomWindow from 'interfaces/Utils';
import { UserContext } from 'providers/UserProvider';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { PermutivePageConfig } from './entities';

export * from './setPermutivePageConfig';

declare let window: CustomWindow;

interface PermutiveProps {
  permutivePageConfig: PermutivePageConfig;
  pageAdConfig: PageAdConfig;
}

const Permutive = ({ permutivePageConfig, pageAdConfig }: PermutiveProps) => {
  const [, setLoaded] = useState<{ loaded: null } | null>(null);
  const { hasLoggedIn } = useContext(UserContext);

  const noNativoAds = pageAdConfig.tags?.includes('no-nativo') || pageAdConfig.topics?.includes('no-nativo');

  return (
    <>
      <Script
        id='permutive-1'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,o,n,i){if(!e){e=e||{},window.permutive=e,e.q=[];var t=function(){return([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,function(e){return(e^(window.crypto||window.msCrypto).getRandomValues(new Uint8Array(1))[0]&15>>e/4).toString(16)})};e.config=i||{},e.config.apiKey=o,e.config.workspaceId=n,e.config.environment=e.config.environment||"production",(window.crypto||window.msCrypto)&&(e.config.viewId=t());for(var g=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<g.length;r++){var w=g[r];e[w]=function(o){return function(){var n=Array.prototype.slice.call(arguments,0);e.q.push({functionName:o,arguments:n})}}(w)}}}(window.permutive,"${process.env.PERMUTIVE_WORKSPACE_API_KEY}","${process.env.PERMUTIVE_WORKSPACE_ID}",{});
          window.googletag=window.googletag||{},window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var e=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",e?JSON.parse(e):[]);var o=window.localStorage.getItem("permutive-id");o&&(window.googletag.pubads().setTargeting("puid",o),window.googletag.pubads().setTargeting("ptime",Date.now().toString())),window.permutive.config.viewId&&window.googletag.pubads().setTargeting("prmtvvid",window.permutive.config.viewId),window.permutive.config.workspaceId&&window.googletag.pubads().setTargeting("prmtvwid",window.permutive.config.workspaceId)}});
          window.permutive.readyWithTimeout=function(e,i,t){var u=!1,n=function(){u||(e(),u=!0)};(t=t||1/0)!==1/0&&window.setTimeout(n,t),permutive.ready(n,i)};
        `,
        }}
      />
      <Script
        id='permutive-2'
        strategy='afterInteractive'
        onLoad={() => {
          window.permutive.readyWithTimeout(
            () => {
              const ntv = window.ntv || {};
              ntv.cmd = ntv.cmd || [];

              ntv.cmd.push(() => {
                if (!window.location.search.includes('prx_t') && !noNativoAds)
                  // Run only with Non Brand studio pages
                  window.PostRelease.Start();

                setTimeout(() => {
                  let nativoCampaignId: string = '';

                  if (window.ntvar && window.ntvar.campaignID) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    nativoCampaignId = window.ntvar.campaignID.toString();
                  }

                  if (permutivePageConfig) {
                    setLoaded({
                      loaded: window.permutive.addon('web', {
                        page: {
                          ...permutivePageConfig,
                          LoginStatus: hasLoggedIn,
                          nativoCampaignId,
                        },
                      }),
                    });
                  }
                }, 10000);
              });
            },
            'realtime',
            3000,
          );
        }}
        src={`https://${process.env.PERMUTIVE_ORGANIZATION_ID}.edge.permutive.app/${process.env.PERMUTIVE_WORKSPACE_ID}-web.js`}
      />
    </>
  );
};

export default Permutive;
