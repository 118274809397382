import React from 'react';
import RightRailFlexArticles from './RightRailFlexArticles';
import { RightRailMultipleContainer } from './Styles';

interface RightRailFlexArticlesListProps {
  count: number;
  marginTop: number;
}

const RightRailFlexArticlesList: React.FC<RightRailFlexArticlesListProps> = ({
  count,
  marginTop,
}: RightRailFlexArticlesListProps) => (
  <RightRailMultipleContainer $marginTop={marginTop}>
    {Array.from({ length: count }, (e, i) => (
      <RightRailFlexArticles
        key={`right-rail-article-${i}`}
        id={i}
      />
    ))}
  </RightRailMultipleContainer>
);

export default RightRailFlexArticlesList;
