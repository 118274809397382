import Logger from './logger';

export default class ProdLogger extends Logger {
  // eslint-disable-next-line class-methods-use-this
  formatOperation(type: string, name: string, params: Record<string, any>, duration: number) {
    return JSON.stringify(
      {
        duration,
        name,
        params,
        type,
      },
      null,
      0,
    );
  }
}
