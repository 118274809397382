/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const Facebook = () => (
  <svg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='facebook-square' className='svg-inline--fa fa-facebook-square fa-w-14 social-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
    <path d='M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z' />
  </svg>
);

export const FacebookFooter = () => (
  <svg viewBox='0 0 12 20' xmlns='http://www.w3.org/2000/svg' tabIndex='-1' aria-label='Facebook' aria-hidden='true'>
    <path d='M8.4 3.5c-.4 0-.8.4-.8 1.1v2.2h3.9v3.3h-4v9.8H3.8v-9.7H.5V6.9h3.3V5c0-2.8 2-4.9 4.6-4.9h3.1v3.4H8.4z' />
  </svg>
);
