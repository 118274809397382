export const COMMON_TEST_IDS = {
  AUTHORS_CONTAINER: 'authors-container',
  AUTHOR_BY_TEXT: 'author-by-text',
  AUTHOR_NAME: 'author-name',
  BY_LINE_AUTHOR: 'by-line-author',
  CRASH_COURSE_TOUT: 'crash-course-tout',
  DATE_CONTAINER: 'date-container',
  DESKTOP_BREADCRUMBS: 'desktop-breadcrumbs',
  DISCLOSURE_STRIPE: 'disclosure-stripe',
  FORTUNE_LOGO: 'fortune-logo',
  HERO_IMAGE: 'hero-image',
  HERO_IMAGE_LINK: 'hero-image-link',
  HERO_TITLE_LINK: 'hero-title-link',
  HERO_TITLE_TEXT: 'hero-title-text',
  HOMEPAGE_FEATURED_SECTIONS: 'homepage-featured-sections',
  HOMEPAGE_RIGHT_RAIL: 'homepage-right-rail',
  NEXT_ARROW: 'next-arrow',
  PAGINATION_INFO: 'pagination-info',
  PAGINATION_NEXT: 'pagination-next',
  PAGINATION_NUMBER: 'pagination-number',
  PAGINATION_NUMBER_HIDDEN: 'pagination-hidden-number',
  PAGINATION_PREVIOUS: 'pagination-previous',
  PAGINATION_WRAPPER: 'pagination-wrapper',
  PINNED_ARTICLES_LIST: 'pinned-articles-list',
  PINNED_ARTICLE_CARD: 'pinned-article-card',
  PINNED_SUBSECTIONS_LIST: 'pinned-subsections-list',
  PINNED_SUBSECTION_CARD: 'pinned-subsections-card',
  PREVIOUS_ARROW: 'next-arrow',
  RELATED_ARTICLES_RR: 'related-articles-rr',
  REVIEWED_BY_TEXT: 'reviewed-by-text',
  REVIEWER_NAME: 'reviewer-name',
  RIGHT_RAIL_AD: 'right-rail-ad',
  RIGHT_RAIL_STICKY_AD: 'right-rail-sticky-ad',
  RIGHT_RAIL_STICKY_AD_HUB: 'right-rail-sticky-ad-hub-page',
  SUBMENU: 'submenu',
  TABLET_AND_MOBILE_BREADCRUMBS: 'tablet-and-mobile-breadcrumbs',
  THE_LATEST_MODULE: 'the-latest-module',
  TOP_ARTICLES: 'top-articles',
};

export type TestIds = {
  [key in keyof typeof COMMON_TEST_IDS]?: string;
};

// TODO: FECM-1025 no reason to use a method that only gets values from the COMMON_TEST_IDS object, it should be removed
const getCommonTestId = (value: keyof TestIds) => COMMON_TEST_IDS[value];

export default getCommonTestId;
