import styled from 'styled-components';
import { Theme, getColor } from 'styles/theme';
import { arrowLeft } from 'styles/icons';

export const PrevArrowWrapper = styled.button<{ theme: Theme }>`
  width: 30px;
  height: 35px;
  border-color: ${getColor('iconPrimary')};


  &.slick-prev::before {
    display: none;
  }
  &.slick-prev {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: ${getColor('iconAccentPrimary')};
  }
  &:focus {
    border-color:  ${getColor('iconSecondary')};
  }
`;
export const PrevArrow = styled.span`
  ${arrowLeft}
  border-width: 0 2px 2px 0;
  padding: 4px;
`;
