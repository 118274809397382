import { styled } from 'styled-components';
import { color, font } from 'styles/globals';

export const StickyFooterContainer = styled.div`
  font-family: ${font.graphikCond};
  font-size: 16px;
  text-align: center;
  position: sticky;
  z-index: 9999;
  box-shadow: 0px 8px 20px 0px ${`${color.primaryBlack}10`};
  border-top: 1px solid ${color.darkGray};
  height: auto;
  bottom: 0;
  background-color: ${color.primaryWhite};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.color || 'transparent'};
  border-bottom: 4px solid ${color.primaryBlack};
  height: 46px;

  svg {
    fill: ${color.primaryWhite};
    max-width: 92px;
    max-height: 20.44px;
  }

  .fortune-nl-logo {
    display: flex;
    margin: 12px 24px;

    svg {
      margin-right: 9px;
    }
  }

  .close-icon {
    margin: auto 14px auto auto;

    button {
      width: 24px;
    }
  }
`;

export const StickyFooterContent = styled.div`
  padding: 32px 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin: 0 16px 8px 16px;

  .line {
    margin: auto;
    width: 48px;
    border-bottom: 1px solid ${color.secondaryLightGray};
    padding-top: 8px;
  }
`;

export const Description = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
`;
