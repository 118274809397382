import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const RoundList = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  order: 1;

  > ul {
    display: flex;
  }

  @media (${breakpoint.mdMin}) {
    > ul {
      align-items: start;
    }
  }

  @media (${breakpoint.xxlMin}) {
    order: 2;
    margin-left: 8px;
  }
`;

export const RoundLink = styled.li<{ theme: Theme }>`
  align-items: center;
  
  a {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
    border: 1px solid ${getColor('iconPrimary')};
    border-radius: 50%;
    margin-right: 4px;

    svg {
      width: 12px;
    }
  }

  a:hover {
    border-color: ${getColor('iconAccentPrimary')};

    svg {
      fill: ${getColor('iconAccentPrimary')};
    }
  }

  a:active, a:focus {
    border-color: ${getColor('iconSecondary')};

    svg {
      fill: ${getColor('iconSecondary')};
    }
  }
`;

export const SquareList = styled.ul`
  display: flex;
  gap: 16px;
`;

export const SquareLink = styled.li<{ theme: Theme }>`
  svg {
    height: 20px;
    width: 20px;
    fill: ${getColor('iconAccentPrimary')};
  }

  a:hover {
    svg {
      cursor: pointer;
      fill: ${getColor('iconSecondary')};
    }
  }

  a:active, a:focus {
    svg {
      fill: ${getColor('iconTertiary')};
    }
  }
`;
