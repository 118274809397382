import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const DrawerToggle = styled.input`
  &.drawer-toggle {
    display: none;

  &:checked {
    & ~ .drawer-menu {
      visibility: visible;
      left: 0;
    }

    & ~ .overlay {
      visibility: visible;
      opacity: 0.5;
    }
  }
}
`;

export const DrawerToggleButton = styled.label<{ theme: Theme }>`
  &.hamburger-toggle {
    display: block;
    width: 30px;
    height: 23px;
    cursor: pointer;
    margin-right: 24px;
    position: relative;

    @media (${breakpoint.lgMin}) {
		  margin-right: 48px;	
	  }

    &:hover {
      span, &::before, &::after {
        background-color: ${getColor('accentPrimary')};
      }
    }

    &:focus, &:active {
      span, &::before, &::after {
        background-color: ${getColor('iconSecondary')};
      }
    }

    span {
      display: block;
      width: 100%;
      height: 3px;
      background-color: ${getColor('iconPrimary')};
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: ${getColor('iconPrimary')};
    }

    &::before {
      top: 8px;
    }

    &::after {
      bottom: 4px;
    }
  }

  &.close-menu {
    display: block;
    width: 26px;
    height: 24px;
    cursor: pointer;
    position: relative;

    @media (${breakpoint.mdMin}) {
      width: 30px;
      height: 33px;
	  }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 22px;
      background-color: ${getColor('iconPrimary')};
      border-radius: 4px;

      @media (${breakpoint.mdMin}) {
		    width: 3px;
        height: 33px;
	    }
    }

    &::before {
      left: 12px;
      transform: rotate(45deg);
    }

    &::after {
      left: 12px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before, &::after {
        background-color: ${getColor('accentPrimary')};
      }
    }

    &:focus, &:active {
      &::before, &::after {
        background-color: ${getColor('iconSecondary')};
      }
    }
  }
`;
