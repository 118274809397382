/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
import { Analytics } from '@segment/analytics-node';
import { RedirectClickData, RedirectSegmentData } from 'interfaces/Analytics';
import logger from 'utils/log';

export class SegmentService {
  private analytics: Analytics;

  // eslint-disable-next-line no-use-before-define
  private static instance: SegmentService;

  // Private constructor to prevent instantiation
  private constructor() {
    this.analytics = new Analytics({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_REDIRECTS_WRITE_KEY! });
  }

  // Public method to get the single instance of the class
  public static getInstance(): SegmentService {
    if (!SegmentService.instance) {
      SegmentService.instance = new SegmentService();
    }
    return SegmentService.instance;
  }

  public trackClickEvent(event: string, type: string, userId: string, clickEventData: RedirectClickData) {
    const segmentData: RedirectSegmentData = {
      click_id: clickEventData.pvUUID,
      click_iso_date: clickEventData.clickISODate,
      click_link: clickEventData.redirectUrl,
      click_text: '',
      click_type: type,
      device_type: clickEventData.deviceType,
      post_id: clickEventData.postId,
      pv_iso_date: clickEventData.pvISODate,
      pv_uuid: clickEventData.pvUUID,
      source_id: clickEventData.sourceId,
    };

    try {
      logger.message('INFO', 'TRACKING REDIRECT CLICK EVENT SEGMENT', { event, segmentData, type, userId }, 0);
      this.analytics.track({
        anonymousId: userId,
        event,
        properties: {
          ...segmentData,
        },
      });
    } catch (error) {
      logger.error('ERROR TRACKING EVENT', { error, errorString: error?.toString() });
    }
  }
}

export default SegmentService;
