import React, { useCallback, useEffect } from 'react';
import { HtmlInhouseAdProps } from 'interfaces/ads/InhouseAd';
import { InhouseAdTrackingObject } from 'services/Gtm/entities';
import { pushToDataLayer } from 'services/Gtm/functions';

interface InhouseAdProps {
  inhouseAd: HtmlInhouseAdProps;
  pageType?: string;
  placementName?: string;
}

const HtmlAd: React.FC<InhouseAdProps> = ({ inhouseAd, pageType, placementName }) => {
  const getTrackingEvent = useCallback(
    (event: string): InhouseAdTrackingObject => ({
      adId: inhouseAd.adId,
      event,
      placementId: `${pageType}-${placementName}`,
      ...inhouseAd.tracking,
    }),
    [inhouseAd],
  );

  useEffect(() => {
    // Ad load event
    // This does not count as an impression
    // In fact, this helps us find out how many ad displays
    // in page have actually generated impressions
    pushToDataLayer(getTrackingEvent('fortune.inhouseAd.request'));
  }, [getTrackingEvent]);

  return <div dangerouslySetInnerHTML={{ __html: inhouseAd.html }} />;
};

export default HtmlAd;
