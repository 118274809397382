import React, { createContext, useMemo } from 'react';
import { Article } from 'interfaces/content/articles/Articles';
import { Page } from 'interfaces/content/articles/Post';

interface ArticleProviderProps {
  article?: Article;
  page?: Page;
}

interface ArticleContextProps {
  article: Article | null;
  postId: number | null;
}

export const ArticleContext = createContext<ArticleContextProps>({
  article: null,
  postId: null,
});

export const ArticleProvider: React.FC<ArticleProviderProps> = ({ children, article, page }) => {
  let postId = article ? article.postId : null;
  if (page && page.pageId) {
    postId = page.pageId;
  }
  const memoizedArticleState = useMemo(() => ({ article: null, postId }), [postId]);

  return <ArticleContext.Provider value={memoizedArticleState}>{children}</ArticleContext.Provider>;
};
