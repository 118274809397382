/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useRef } from 'react';

type IFrameLoadingTypes = 'eager' | 'lazy' | undefined;

interface IFrameProps {
  ariaLabel: string;
  children?: JSX.Element | JSX.Element[];
  id: string;
  frameBorder?: string;
  hasResponsiveHeightScript: boolean;
  height: string;
  loading: IFrameLoadingTypes;
  scrolling?: string;
  src: string;
  style: { [key: string]: any };
  title: string;
  width: string;
  className?: string;
}

const IFrame = (props: IFrameProps) => {
  const {
    ariaLabel,
    children,
    id,
    frameBorder,
    hasResponsiveHeightScript,
    height,
    loading,
    scrolling,
    src,
    style,
    title,
    width,
    className = '',
  } = props;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleResponsiveHeightScript = (e: MessageEvent) => {
    const responsiveHeight = e.data['datawrapper-height'];

    if (!responsiveHeight) return;

    if (iframeRef.current && iframeRef.current.contentWindow === e.source) {
      iframeRef.current.style.height = `${responsiveHeight[Object.keys(responsiveHeight)[0]]}px`;
    }
  };

  useEffect(() => {
    if (hasResponsiveHeightScript && iframeRef.current) {
      window.addEventListener('message', handleResponsiveHeightScript);
    }

    return () => window.removeEventListener('message', handleResponsiveHeightScript);
  }, [hasResponsiveHeightScript]);

  return (
    <iframe
      className={className}
      aria-label={ariaLabel}
      id={id}
      frameBorder={frameBorder}
      height={height}
      loading={loading}
      ref={iframeRef}
      scrolling={scrolling}
      src={src}
      style={style}
      title={title}
      width={width}
    >
      {children}
    </iframe>
  );
};

export default IFrame;
