import { ReactNode, Component, ErrorInfo } from 'react';
import { logToBackend } from 'utils/log';

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode | ReactNode[];
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logToBackend('crash', error.name, {
      stackTracek: errorInfo.componentStack,
      url: window.location.href,
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
