import { css } from 'styled-components';
import { Theme, getColor } from 'styles/theme';

const arrow = css`
  border-style: solid;
  border-color: inherit;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  width: 9px;
  height: 9px;
  content: '';
`;

export const arrowRight = css`
  ${arrow}
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 1px;
  margin-right: 2px;
  margin-top: 3px;
`;

export const arrowUp = css`
  ${arrow}
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

export const arrowDown = css`
  ${arrow}
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2.5px;
`;

export const arrowLeft = css`
  ${arrow}
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-bottom: 2px;
  margin-top: 3px;
`;

export const checkmark = css<{ theme: Theme }>`
  --width: 5.5px;
  --height: 11px;
  --border-width: 2.5px;
  --color: ${getColor('iconWhite')};
  background-color: transparent;
  width: var(--width);
  height: var(--height);
  border: solid var(--color);
  border-width: 0 var(--border-width) var(--border-width) 0;
  transform: rotate(45deg);
  border-radius: 1px;
`;

export const yesNoIcons = css`
  .has-vivid-cyan-blue-color {
      content: url('/icons/ProIcon.svg');
      padding-left: 24px;
    }

    .has-vivid-purple-color {
      content: url('/icons/ConIcon.svg');
      padding-left: 24px;
    }
`;
