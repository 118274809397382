import { InfoIcon } from 'icons/InfoIcon';
import * as S from './InfoToggletipStyles';

interface InfoToggletipProps {
  content: string;
  position?: string | 'top' | 'bottom' | 'left' | 'right' | 'bottom-right' | 'bottom-left';
  color?: string;
  dataCy?: string;
  className?: string;
  variant?: 'green' | 'black';
}

const InfoToggletip = ({ content, position, color, dataCy, variant, className }: InfoToggletipProps) => (
  <S.InfoToggletipWrapper
    tabIndex={0}
    aria-label={content}
    role='tooltip'
    data-tooltip={content}
    $colorVar={color}
    $position={position}
    $variant={variant}
    data-cy={dataCy}
    className={className}
    onKeyDown={(e) => {
      if (e.key === 'Escape') (e.target as HTMLElement).blur();
    }}
  >
    <InfoIcon />
  </S.InfoToggletipWrapper>
);

export default InfoToggletip;
