import React from 'react';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import { LeaderboardAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface LeaderboardProps {
  id: number;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ id }: LeaderboardProps) => (
  <AdSlotToggle
    component={LeaderboardAd}
    placementName='Leaderboard'
    index={id + 1}
    slotId={`Leaderboard${id}`}
    dataCy={ADS_TEST_IDS.LEADERBOARD}
  />
);

export default Leaderboard;
