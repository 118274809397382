import styled from 'styled-components';
import { Theme, getColor } from 'styles/theme';
import { arrowRight } from 'styles/icons';

export const NextArrowWrapper = styled.button<{ theme: Theme }>`
  width: 30px;
  height: 35px;
  border-color: ${getColor('iconPrimary')};

  &.slick-next::before {
    display: none;
  }
    
  &.slick-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: ${getColor('iconAccentPrimary')};
  }
    
  &:focus, &:active {
    border-color:${getColor('iconSecondary')};
  }
`;

export const NextArrow = styled.span`
  ${arrowRight}
  border-width: 0 2px 2px 0;
  padding: 4px;
`;
