import React, { useContext, useEffect, useRef, useState } from 'react';
import { IStyledComponent } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { AdContextInterface } from 'services/Ad';
import { PersonalizationContext } from 'providers/PersonalizationProvider';
import { HtmlInhouseAdProps, InstreamInhouseAdProps, StickyInhouseAdProps } from 'interfaces/ads/InhouseAd';
import { PlacementName, ProgrammaticAdInfo } from 'interfaces/ads/Ad';
import { PageType } from 'interfaces/content/articles/Post';
import InhouseAd from 'components/Ad/InhouseAd';
import sponsoredLineItemIds from 'services/Ad/utils/sponsoredLineItemIds';

interface AdSlotProps {
  adContext: AdContextInterface;
  component?: IStyledComponent<'web', {}>;
  // this is optional for now because we are enabling in-house ads only for articles; should transform into a
  // mandatory property in the future
  pageType?: PageType;
  placementName: PlacementName;
  index: number;
  slotId: string;
  dataCy?: string;
  personalized?: boolean;
  hideGAM?: boolean;
}

let interval: NodeJS.Timeout | null = null;

const AdSlot: React.FC<AdSlotProps> = ({
  adContext,
  component,
  pageType = PageType.NO_TYPE,
  placementName,
  index,
  slotId,
  dataCy = '',
  personalized = false,
  hideGAM,
}: AdSlotProps) => {
  const {
    initialized: adLibInitialized,
    getPlacementConfig: getAdSlotConfig,
    displayAdSlot,
    registerAdSlotRenderCallback,
  } = adContext;
  const [isLibAdSponsored, setIsLibAdSponsored] = useState(!hideGAM);
  const hasLibAdDisplayed = useRef(false);
  const isRenderCallbackRegistered = useRef(false);
  const { performAdUpdates: performInhouseAdUpdates, getAdForSlot: getInhouseAdForSlot } =
    useContext(PersonalizationContext);
  const [inhouseAd, setInhouseAd] = useState<InstreamInhouseAdProps | StickyInhouseAdProps | HtmlInhouseAdProps | null>(
    null,
  );

  const [adSlotRef, isAdSlotInViewport] = useInView({ threshold: 0 });

  useEffect(() => {
    if (personalized) {
      // eslint-disable-next-line no-void
      void performInhouseAdUpdates(pageType, placementName);
    }
  }, []);

  useEffect(() => {
    if (personalized) {
      // search for ad in persisted ads
      const adData = getInhouseAdForSlot(pageType, placementName, index);
      setInhouseAd(adData);
    }
  }, [getInhouseAdForSlot, index, placementName, pageType]);

  const adSlotRenderedCallback = (event: ProgrammaticAdInfo) => {
    const { lineItemId } = event;
    setIsLibAdSponsored(lineItemId !== null && sponsoredLineItemIds.includes(lineItemId));
  };

  useEffect(() => {
    if (adLibInitialized && !hideGAM) {
      const placementConfig = getAdSlotConfig(placementName);
      if (!isRenderCallbackRegistered.current) {
        registerAdSlotRenderCallback(slotId, adSlotRenderedCallback);
        isRenderCallbackRegistered.current = true;
      }

      if (isAdSlotInViewport && (!inhouseAd || isLibAdSponsored)) {
        const slotConfig = {
          id: slotId,
          index,
          path: placementConfig.path,
          placementName,
        };

        if (!hasLibAdDisplayed.current) {
          hasLibAdDisplayed.current = true;
          displayAdSlot(slotConfig);
          interval = setInterval(() => {
            displayAdSlot(slotConfig);
          }, placementConfig.refreshRate * 999999);
        }
      } else if (interval) {
        // Stop refreshing ads if not in viewport
        clearInterval(interval);
        // TODO if sponsored ad, destroy ad slot in gam; see warning console
      }
    }
  }, [
    displayAdSlot,
    getAdSlotConfig,
    isAdSlotInViewport,
    adLibInitialized,
    placementName,
    slotId,
    index,
    inhouseAd,
    adSlotRenderedCallback,
    isLibAdSponsored,
    registerAdSlotRenderCallback,
    hideGAM,
  ]);

  const Comp = component || 'div';

  // don't show any ad if GAM is hidden and there is no personalised inhouse ad available
  if (!inhouseAd && hideGAM) {
    return null;
  }

  if (inhouseAd && !isLibAdSponsored) {
    // override default GAM ad with an inhouse ad
    return (
      <InhouseAd
        inhouseAd={inhouseAd}
        pageType={pageType}
        placementName={placementName}
        data-cy={dataCy}
      />
    );
  }
  return (
    <Comp
      id={slotId}
      ref={adSlotRef}
      data-cy={dataCy}
    />
  );
};

export default AdSlot;
