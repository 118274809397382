/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/prefer-default-export */
import { RedirectClickData, UseAnalyticsData } from 'interfaces/Analytics';
import { useEffect, useMemo, useState, useContext } from 'react';
import { getParser } from 'bowser';
import { ArticleContext } from 'providers/ArticleProvider';
import { v4 as uuidv4 } from 'uuid';

const getImpressionUrlEncoded = () => encodeURIComponent(window.location.href);

const getDeviceType = () => {
  const parser = getParser(navigator.userAgent);
  return parser.getPlatformType();
};

const getSourceId = (href: string) => {
  try {
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    return params.get('src') || params.get('source') || 'none found';
  } catch {
    return 'none found';
  }
};

const generateISODate = () => new Date().toISOString();

export const useAnalyticsData = (href?: string): UseAnalyticsData => {
  const [analyticsData, setAnalyticsData] = useState<RedirectClickData | null>(null);
  const { postId } = useContext(ArticleContext) || {};

  const sourceId = useMemo(() => (href ? getSourceId(href) : ''), [href]);

  const pvUUID = useMemo(() => uuidv4(), []);

  useEffect(() => {
    const impressionUrl = getImpressionUrlEncoded();
    const pvISODate = generateISODate();
    const deviceType = getDeviceType();

    const data = {
      clickISODate: '',
      deviceType,
      impressionUrl,
      postId,
      pvISODate,
      pvUUID,
      redirectUrl: href ? encodeURIComponent(href) : 'ERROR: NO HREF PROVIDED',
      sourceId,
    };

    setAnalyticsData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: analyticsData,
  };
};
